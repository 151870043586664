import CONSTANTS from './constants';
// -------------------------------------------------  home
export function updateState_homeView(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_HOME,
    data: state
  };
}
// -------------------------------------------------  apps view

export function updateState_appsView(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_APPS,
    data: state
  };
}
// -------------------------------------------------  app category

export function updateState_appCategory(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_APPCATEGORY,
    data: state
  };
}
// -------------------------------------------------  movies

export function updateState_movies(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_MOVIES,
    data: state
  };
}
// -------------------------------------------------  movie category

export function updateState_movieCategory(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_MOVIECATEGORY,
    data: state
  };
}
// -------------------------------------------------  tvshows

export function updateState_tvshows(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_TVSHOWS,
    data: state
  };
}
// -------------------------------------------------  tvshow category

export function updateState_tvshowcategory(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_TVSHOWCATEGORY,
    data: state
  };
}
// -------------------------------------------------  games

export function updateState_games(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_GAMES,
    data: state
  };
}
// -------------------------------------------------  music

export function updateState_music(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_MUSIC,
    data: state
  };
}
// -------------------------------------------------  search

export function updateState_search(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_SEARCH,
    data: state
  };
}
// -------------------------------------------------  settings

export function updateState_settings(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_SETTINGS,
    data: state
  };
}

// -------------------------------------------------  filters

export function updateState_filters(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_FILTERS,
    data: state
  };
}
// -------------------------------------------------  myapps

export function updateState_myapps(state) {
  return {
    type: CONSTANTS.UPDATE_STATE_MYAPPS,
    data: state
  };
}
