import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router';
import Loading from '../views/loading';
import history from './history';

const Controller = lazy(() => import('../components/controller'));

const Routes = () => (
  <Router history={history}>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route path="/:view/:section?/:helper?" component={Controller} />
      </Switch>
    </Suspense>
  </Router>
);

export { Routes as default };
