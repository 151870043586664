import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// reducers
import customReducer from './custom/reducers';
import stateReducer from './state/reducers';
// actions
import * as customActions from './custom/actions';
import * as stateActions from './state/actions';

export default createStore(combineReducers({ customReducer, stateReducer }), applyMiddleware(thunk));

export { customActions, stateActions };
